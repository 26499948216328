import { defineStore } from 'pinia'

export interface ConfirmationModalState {
  modal: ModalConfirmationProps | null
  resolve: ((value: boolean) => void) | null
}

export interface ModalConfirmationProps {
  /**
   * @deprecated Please use `if(await showConfirmation())` instead
   */
  onConfirm?: () => void
  content?: string
  labelConfirm?: string
  labelCancel?: string
}

export const useConfirmationModalStore = defineStore('confirmation-modal', {
  state: (): ConfirmationModalState => ({
    modal: null,
    resolve: null,
  }),
  actions: {
    openModal(
      props: ModalConfirmationProps,
      resolve: (value: boolean) => void
    ) {
      this.modal = props
      this.resolve = resolve
    },
    closeModal(isConfirmed: boolean) {
      if (isConfirmed) {
        this.modal?.onConfirm?.()
      }

      this.resolve?.(isConfirmed)

      // Clear the modal
      this.modal = null
      this.resolve = null
    },
  },
})

export const useConfirmation = () => {
  const store = useConfirmationModalStore()

  const showConfirmation = async (options: ModalConfirmationProps) =>
    new Promise<boolean>((resolve) => {
      store.openModal(options, resolve)
    })

  return {
    showConfirmation,
  }
}
